var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"rolesForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('b-loading',{attrs:{"is-full-page":false,"can-cancel":false},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}}),_c('form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.$emit('save-role'))}}},[_c('validation-provider',{attrs:{"rules":"required","name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"is-primary",attrs:{"label":"Name","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"is-primary",attrs:{"label":"Description","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"textarea"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)]}}],null,true)}),_c('b-field',[_c('div',{staticClass:"buttons is-spaced-top"},[_c('b-button',{attrs:{"disabled":invalid,"tag":"input","native-type":"submit","type":"is-success","value":"Save Role","icon-left":"save"}},[_vm._v(" save role ")]),_c('b-button',{attrs:{"type":"is-info"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" cancel ")])],1)])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }