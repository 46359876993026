<template>
  <b-table
    :data="users"
    :loading="loading"
    :paginated="true"
    :per-page="perPage"
    striped
    checkable
    :checked-rows.sync="checkedRows"
  >
    <b-table-column
      v-slot="props"
      custom-key="avatar"
    >
      <figure class="image is-32x32">
        <img
          style="width: 32px; height: 32px"
          class="is-rounded"
          :src="props.row.userPhoto ? props.row.userPhoto : defaultImage"
          :alt="props.row.username"
        >
      </figure>
    </b-table-column>

    <b-table-column
      sortable
      searchable
      label="Name"
      field="fullName"
    >
      <template
        slot="searchable"
        slot-scope="props"
      >
        <b-input
          v-model="props.filters['fullName']"
          placeholder="Search..."
          icon="search"
        />
      </template>

      <template v-slot="props">
        <p class="has-text-weight-bold">
          {{ props.row.fullName }}
        </p>
        <span class="has-text-info">
          {{ props.row.email }}
        </span>
      </template>
    </b-table-column>

    <b-table-column
      label="Current Roles"
      field="role"
      sortable
      searchable
    >
      <template
        slot="searchable"
        slot-scope="props"
      >
        <b-input
          v-model="props.filters['role']"
          placeholder="Search..."
          icon="search"
        />
      </template>

      <template v-slot="props">
        <b-button
          v-if="props.row.role === selectedRole.name"
          type="is-primary"
          icon-right="check"
        />
        <span v-else>
          {{ props.row.role | role }}
        </span>
      </template>
    </b-table-column>

    <b-table-column
      v-if="Object.keys(selectedRole).includes('name')"
      v-slot="props"
      centered
      label="Add/Remove"
      custom-key="actions"
    >
      <b-button
        v-show="props.row.role === selectedRole.name"
        type="is-danger"
        icon-right="minus"
        @click="revokeRole({ userId: [props.row.id], roleId: selectedRole.id })"
      />

      <b-button
        v-show="props.row.role !== selectedRole.name"
        type="is-success"
        icon-right="plus"
        @click="assignRole({ userId: [props.row.id], roleId: selectedRole.id })"
      />
    </b-table-column>

    <template slot="empty">
      <empty-table table-name="users" />
    </template>

    <template slot="footer">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <div
              class="content"
              v-show="Object.keys(selectedRole).includes('name')"
            >
              <b-button
                type="is-success"
                icon-left="plus"
                @click="assignToRole"
              >
                add to role
              </b-button>
            </div>
          </div>
        </div>

        <div class="level-right">
          <div class="level-item">
            <b-dropdown
              v-model="perPage"
              aria-role="list"
              position="is-bottom-left"
            >
              <button
                class="button is-info"
                slot="trigger"
                slot-scope="{ active }"
              >
                <span>{{ perPage }}</span>
                <b-icon
                  :icon="active ? 'chevron-up' : 'chevron-down'"
                  size="is-small"
                />
              </button>

              <template v-for="(i) in pages">
                <b-dropdown-item
                  :key="i"
                  :value="i"
                >
                  {{ i }}
                </b-dropdown-item>
                <hr
                  :key="i"
                  class="dropdown-divider"
                >
              </template>
            </b-dropdown>
          </div>
        </div>
      </div>
    </template>
  </b-table>
</template>

<script>
import { EmptyTable } from '@/components/Shared';

import defaultImage from '@/assets/images/avatar.svg';

export default {

  name: 'RolesAssignmentTable',

  components: {
    EmptyTable,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },

    users: {
      type: Array,
      required: true,
    },

    selectedRole: {
      type: Object,
      required: true,
    },

    currentRecord: {
      type: Number,
      required: true,
    },

    lastRecord: {
      type: Number,
      required: true,
    },

    totalRecords: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    defaultImage,
    checkedRows: [],
    perPage: 10,
    pages: [10, 25, 50, 100],
  }),

  filters: {
    role(value) {
      return value.replace(/_/g, ' ').substring(5).toLowerCase();
    },
  },

  methods: {
    async assignToRole() {
      const users = this.checkedRows.map((user) => user.id);
      await this.$store.dispatch(
        'Roles/assignRole', { userId: users, roleId: this.selectedRole.id },
      );
      await this.$store.dispatch('Users/fetchUsers');
    },

    async assignRole(payload) {
      try {
        await this.$store.dispatch('Roles/assignRole', payload);
        await this.$store.dispatch('Users/fetchUsers');
        return this.$buefy.notification.open({
          message: 'Successfully assigned role',
          type: 'is-success',
        });
      } catch (error) {
        return this.$buefy.notification.open({
          message: error.message,
          type: 'is-warning',
        });
      }
    },

    async revokeRole(payload) {
      try {
        await this.$store.dispatch('Roles/revokeRole', payload);
        await this.$store.dispatch('Users/fetchUsers');
        return this.$buefy.notification.open({
          message: 'Successfully revoked role',
          type: 'is-success',
        });
      } catch (error) {
        return this.$buefy.notification.open({
          message: error.message,
          type: 'is-warning',
        });
      }
    },
  },
};
</script>

<style lang="css" scoped>
</style>
