<template>
  <b-table
    :data="roles"
    :loading="loading"
    :narrowed="true"
    striped
  >
    <b-table-column
      v-slot="props"
      label="Name"
      field="name"
    >
      <span class="is-capitalized">
        {{ props.row.name | role }}
      </span>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Description"
      field="description"
    >
      {{ props.row.description | capitalize({ onlyFirstLetter: true }) }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="# Members"
      field="members"
      centered
    >
      {{ props.row.members }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      custom-key="actions"
    >
      <div class="buttons">
        <b-tooltip
          type="is-info"
          label="Users"
          position="is-bottom"
        >
          <b-button
            type="is-text"
            class="has-text-grey"
            icon-right="users"
            icon-pack="fas"
            @click="$emit('fetch-role-assignees', props.row)"
          />
        </b-tooltip>

        <b-tooltip
          type="is-info"
          label="Permissions"
          position="is-bottom"
        >
          <b-button
            type="is-text"
            class="has-text-grey"
            icon-right="key"
            icon-pack="fas"
            @click="$emit('fetch-role-permissions', props.row)"
          />
        </b-tooltip>

        <b-tooltip
          type="is-info"
          label="Edit"
          position="is-bottom"
        >
          <b-button
            type="is-text"
            class="has-text-grey"
            icon-right="edit"
            icon-pack="fas"
            @click="$emit('edit-role', props.row)"
          />
        </b-tooltip>

        <b-tooltip
          type="is-info"
          label="Delete"
          position="is-bottom"
        >
          <b-button
            type="is-text"
            class="has-text-grey"
            icon-right="trash"
            icon-pack="fas"
            @click="$emit('delete-role', props.row.name)"
          />
        </b-tooltip>
      </div>
    </b-table-column>

    <template slot="empty">
      <empty-table table-name="roles" />
    </template>
  </b-table>
</template>

<script>
import { EmptyTable } from '@/components/Shared';

export default {

  name: 'RolesTable',

  components: {
    EmptyTable,
  },

  props: {
    roles: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

  filters: {
    role(value) {
      return value.replace(/_/g, ' ').substring(5).toLowerCase();
    },
  },
};
</script>

<style lang="css" scoped>
</style>
