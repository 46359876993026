<template>
  <validation-observer
    ref="rolesForm"
    v-slot="{ invalid, handleSubmit }"
  >
    <b-loading
      :is-full-page="false"
      v-model="loading"
      :can-cancel="false"
    />

    <form @submit.prevent="handleSubmit($emit('save-role'))">
      <validation-provider
        rules="required"
        name="name"
        v-slot="{ errors, valid }"
      >
        <b-field
          label="Name"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
          class="is-primary"
        >
          <b-input v-model="name" />
        </b-field>
      </validation-provider>

      <validation-provider
        rules="required"
        name="description"
        v-slot="{ errors, valid }"
      >
        <b-field
          label="Description"
          :type="{ 'is-danger': errors[0], 'is-success': valid }"
          :message="errors"
          class="is-primary"
        >
          <b-input
            v-model="description"
            type="textarea"
          />
        </b-field>
      </validation-provider>

      <b-field>
        <div class="buttons is-spaced-top">
          <b-button
            :disabled="invalid"
            tag="input"
            native-type="submit"
            type="is-success"
            value="Save Role"
            icon-left="save"
          >
            save role
          </b-button>

          <b-button
            type="is-info"
            @click="$emit('close')"
          >
            cancel
          </b-button>
        </div>
      </b-field>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {

  name: 'RolesForm',

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    name: {
      get() {
        return this.$store.state.Roles.role.name;
      },

      set(value) {
        return this.$store.commit('Roles/SET_ROLE_NAME', value);
      },
    },

    description: {
      get() {
        return this.$store.state.Roles.role.description;
      },

      set(value) {
        return this.$store.commit('Roles/SET_ROLE_DESCRIPTION', value);
      },
    },
  },

  beforeDestroy() {
    this.$store.commit('Roles/CLEAR_ROLE');
  },
};
</script>

<style lang="css" scoped>
</style>
